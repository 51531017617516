import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";

export const NavbarData = [
  {
    title: "Startsida",
    path: "/",
    icon: <MdIcons.MdSportsSoccer />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Målvakter",
    path: "#0",
    icon: <GiIcons.GiGoalKeeper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "I. Diawara",
        path: "/diawara",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "K. Nordfeldt",
        path: "/nordfeldt",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "K. Stamatopoulos",
        path: "/stamatopoulos",
        icon: <AiIcons.AiFillCaretRight />,
      },
    ],
  },
  {
    title: "Backar",
    path: "#0",
    icon: <MdIcons.MdOutlineSportsKabaddi />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "A. Björnström",
        path: "/axelBjornstrom",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "R. Bonde",
        path: "/bonde",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "E. Edh",
        path: "/eskilEdh",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Faqa",
        path: "/faqa",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "B. Hansen",
        path: "/benjaminHansen",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Milosevic",
        path: "/milosevic",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "R. Modesto",
        path: "/modesto",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "S. Papagiannopoulos",
        path: "/papagiannopoulos",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "C. Shichenje",
        path: "/shichenje",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "M. Thychosen",
        path: "/madsThychosen",
        icon: <AiIcons.AiFillCaretRight />,
      },
    ],
  },
  {
    title: "Mittfältare",
    path: "#0",
    icon: <MdIcons.MdAccessibility />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "A. Ali",
        path: "/abdihakin",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "V. Andersson",
        path: "/victorAndersson",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "T. Ayari",
        path: "/tahaAyari",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "B. Celina",
        path: "/bersantCelina",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "D. Besirovic",
        path: "/dinoBesirovic",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "I. Coulibaly",
        path: "/coulibaly",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "L. Dabo",
        path: "/lamineDabo",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "M. Ellingsen",
        path: "/ellingsen",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "E. Ring",
        path: "/ring",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Saletros",
        path: "/saletros",
        icon: <AiIcons.AiFillCaretRight />,
      },
    ],
  },
  {
    title: "Anfallare",
    path: "#0",
    icon: <IoIcons.IoIosPerson />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "O. Faraj",
        path: "/faraj",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Fesshaie",
        path: "/fesshaie",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "E. Gono",
        path: "/gono",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "J. Guidetti",
        path: "/guidetti",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "H. Meja",
        path: "/meja",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "I. Pittas",
        path: "/ioannisPittas",
        icon: <AiIcons.AiFillCaretRight />,
      },
    ],
  },
  {
    title: "Utlåningar",
    path: "#0",
    icon: <AiIcons.AiOutlineUser />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Z. Elbouzedi",
        path: "/elbouzedi",
        icon: <AiIcons.AiFillCaretRight />,
      },
    ],
  },
  {
    title: "Rykten in",
    path: "#0",
    icon: <AiIcons.AiOutlineUser />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      
    ],
  },
  {
    title: "Exporter",
    path: "#0",
    icon: <AiIcons.AiOutlineUser />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "H. Aviander",
        path: "/aviander",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "P. Abraham",
        path: "/abraham",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Ahmed Fatah",
        path: "/aaa",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "Y. Ayari",
        path: "/yasinAyari",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "N. Bahoui",
        path: "/bahoui",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "S. Brolin",
        path: "/brolin",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "P. Carlgren",
        path: "/carlgren",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "J. Ceesay",
        path: "/ceesay",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "E. Durmaz",
        path: "/eliasDurmaz",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "N. Eliasson",
        path: "/eliasson",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "L. Forsberg",
        path: "/forsberg",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "C. Gravius",
        path: "/gravius",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "B. Hussein",
        path: "/bilal",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Isak",
        path: "/isak",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "R. Jansson",
        path: "/robinJansson",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "K. Kabuye",
        path: "/kabuye",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "E. Kahl",
        path: "/kahl",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "C. Kouakou",
        path: "/kouakou",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "J. Kusi-Asare",
        path: "/jonahKusiAsare",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "J. Lahne",
        path: "/lahne",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "J. Larsson",
        path: "/jordanLarsson",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "O. Linner",
        path: "/linner",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "A. Magashy",
        path: "/magashy",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "J. Mendes",
        path: "/mendes",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "K. Olsson",
        path: "/kristofferOlsson",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "E. Otieno",
        path: "/otieno",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "R. Quaison",
        path: "/quaison",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "N. Stefanelli",
        path: "/stefanelli",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "C. Strandberg",
        path: "/strandberg",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "T. Strannegård",
        path: "/strannegard",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "D. Sundgren",
        path: "/danielSundgren",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "R. Tihi",
        path: "/tihi",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "V. Thill",
        path: "/thill",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "S. Väisänen",
        path: "/vaisanen",
        icon: <AiIcons.AiFillCaretRight />,
      },
    ],
  },
  {
    title: "Övrigt",
    path: "#0",
    icon: <AiIcons.AiOutlineInfoCircle />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      
      {
        title: "Om oss",
        path: "/omOss",
        icon: <AiIcons.AiFillCaretRight />,
      }, 
      {
        title: "Jämför spelare",
        path: "/vs",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "Stockholmsderbyn",
        path: "/derbyStatistik",
        icon: <AiIcons.AiFillCaretRight />,
      }, 
      {
        title: "Sitemap",
        path: "/sitemap",
        icon: <AiIcons.AiFillCaretRight />,
      },
      {
        title: "Filmer",
        path: "/aikHighlights",
        icon: <AiIcons.AiFillCaretRight />,
      }, 
      {
        title: "F vs W",
        path: "/fvsW",
        icon: <AiIcons.AiFillCaretRight />,
      }, 
      {
        title: "AIK Solna FK",
        path: "/aikSolnaFk",
        icon: <AiIcons.AiFillCaretRight />,
      }, 
    ],
  },
];
