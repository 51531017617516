import React from "react";
import styles from "../css/AIKSolnaFK.module.css";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import TableWidget from "../components/TableWidget";
import UpcomingGamesWidget from "../components/UpcomingGamesWidget";
import ResultsWidget from "../components/ResultsWidget";

function AIKSolnaFK() {
  const players = [
    { name: "Sezgin Sahin", number: 2 },
    { name: "Rasmus Hiort", number: 3 },
    { name: "Stavros Kalpakidis", number: 4 },
    { name: "Jonas Forsberg", number: 5 },
    { name: "Alvin Larsson Sjöstedt", number: 6 },
    { name: "Alfred Cöster", number: 7 },
    { name: "David Nordbeck", number: 8 },
    { name: "Daniel Didrik", number: 9 },
    { name: "Linus Wennberg", number: 10 },
    { name: "Dennis Hörtin", number: 11 },
    { name: "Sencer Soguk", number: 12 },
    { name: "Max Forsberg Bergander", number: 13 },
    { name: "Kenny Pavey", number: 14 },
    { name: "Johan Lundqvist", number: 15 },
    { name: "Manuel Walldén", number: 16 },
    { name: "Erik Thorell", number: 17 },
    { name: "Tim Tegnér", number: 19 },
    { name: "David Bergin", number: 20 },
    { name: "Mani Tourang", number: 21 },
    { name: "Felix Isak Anders Thylander", number: 22 },
    { name: "Simon Nildén", number: 23 },
    { name: "Daniel Lundberg", number: 24 },
    { name: "Osama Klay", number: 25 },
    { name: "Eddie Williamson", number: 26 },
    { name: "Johan Östlin", number: 27 },
    { name: "Alexander Snäcke", number: 28 },
    { name: "Nils Jakobsson", number: 29 },
    { name: "Fredrik Åberg", number: 30 },
    { name: "Carl Mellström", number: 31 },
    { name: "Ahmed Allo r35", number: 35 },
    { name: "Johan Wallin", number: null },
    { name: "Mustafa Daler", number: null },
    { name: "Ricardo Mauricio Bustamante Paredes", number: null },
  ];

  return (
    <div className={styles.parent}>
      <Helmet>
        <title>
          {" "}
          AIK Solna FK - Tabell, Kommande matcher, Senaste resultat | AIKstatistik.se
        </title>
      </Helmet>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <img
          className={styles.logoImageOmOss}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.aikStats}>AIK Solna FK</h1>

        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Välkommen till denna undersida som handlar om AIK:s nystartade
            farmarklubb i division 7. På undersidan finns en tabell som visar
            AIK:s placering i tabellen och de senaste resultaten från lagets
            matcher. Du kan också se vilka matcher som är planerade framöver och
            när de kommer att spelas. Truppen finns även listad här.
            <br></br>
            <br></br>
            AIK Solna FK har ett tydligt mål - att ta sig till division 1. Väl
            där kan vi ge våra talanger värdefull speltid i seniormiljö. Fram
            till dess får vi följa lagets resa genom det svenska seriesystemet
            och stödja AIK Solna FK i deras strävan att nå division 1.
          </p>
          <div className={styles.border}></div>
        </div>
        <h4 className={styles.links}>Tabell</h4>
        <TableWidget />
        <div className={styles.border}></div>
        <h4 className={styles.links}>Kommande</h4>
        <UpcomingGamesWidget />
        <div className={styles.border}></div>
        <h4 className={styles.links}>Senaste</h4>
        <ResultsWidget />
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="250px"
            width="250px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>AIK Solna FK</h1>
          <h1 className={styles.info}>Info</h1>

          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
          Välkommen till denna undersida som handlar om AIK:s nystartade
            farmarklubb i division 7. På undersidan finns en tabell som visar
            AIK:s placering i tabellen och de senaste resultaten från lagets
            matcher. Du kan också se vilka matcher som är planerade framöver och
            när de kommer att spelas. Truppen finns även listad här.
            <br></br>
            <br></br>
            AIK Solna FK har ett tydligt mål - att ta sig till division 1. Väl
            där kan vi ge våra talanger värdefull speltid i seniormiljö. Fram
            till dess får vi följa lagets resa genom det svenska seriesystemet
            och stödja AIK Solna FK i deras strävan att nå division 1.
          </h6>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
        <h4 className={styles.links}>Trupp</h4>
        <div className={styles.playerList}>
          {players.map((player, index) => (
            <div
              key={index}
              className={styles.playerItem}
              style={
                index === players.length - 1 ? { marginBottom: "25px" } : {}
              }
            >
              <p className={styles.dataText}>
                {player.name}{" "}
                {player.number && (
                  <span className={styles.playerNumber}>{player.number}</span>
                )}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.border}></div>
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default AIKSolnaFK;
