import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Index from './pages/Index';
import OmOss from './pages/OmOss';
import Kimpioka from "./pages/anfallare/Kimpioka"
import Bilal from './pages/mittfaltare/Bilal';
import Guidetti from './pages/anfallare/Guidetti';
import Bahoui from './pages/anfallare/Bahoui';
import Stefanelli from './pages/anfallare/Stefanelli';
import Faraj from './pages/anfallare/Faraj';
import Meja from './pages/anfallare/Meja';
import Thill from './pages/mittfaltare/Thill';
import Ring from './pages/mittfaltare/Ring';
import Elbouzedi from './pages/mittfaltare/Elbouzedi';
import Ceesay from './pages/mittfaltare/Ceesay';
import YasinAyari from './pages/mittfaltare/YasinAyari';
import TahaAyari from './pages/mittfaltare/TahaAyari';
import VictorAndersson from './pages/mittfaltare/VictorAndersson';
import Papagiannopoulos from './pages/backar/Papagiannopoulos';
import Otieno from './pages/backar/Otieno';
import Milosevic from './pages/backar/Milosevic';
import AxelBjornstrom from './pages/backar/AxelBjornstrom';
import Forsberg from './pages/backar/Forsberg';
import Shichenje from './pages/backar/Shichenje';
import Bonde from './pages/backar/Bonde';
import Mendes from './pages/backar/Mendes';
import Nordfeldt from './pages/malvakter/Nordfeldt';
import Janosevic from './pages/malvakter/Janosevic';
import Modesto from './pages/backar/Modesto';
import Magashy from './pages/mittfaltare/Magashy';
import EliasDurmaz from './pages/mittfaltare/EliasDurmaz';
import Abdihakin from './pages/mittfaltare/Abdihakin';
import Kabuye from './pages/anfallare/Kabuye';
import Isak from './pages/exporter/Isak';
import Kahl from './pages/exporter/Kahl';
import Haliti from './pages/backar/Haliti';
import JimmyDurmaz from './pages/rykten/JimmyDurmaz';
import AAA from './pages/exporter/AAA';
import Faqa from './pages/backar/Faqa';
import Fesshaie from './pages/mittfaltare/Fesshaie';
import Tihi from './pages/backar/Tihi';
import Brolin from './pages/malvakter/Brolin';
import Strannegard from './pages/exporter/Strannegard';
import Abraham from './pages/exporter/Abraham';
import JordanLarsson from './pages/exporter/JordanLarsson';
import Linner from './pages/exporter/Linner';
import DanielSundgren from './pages/exporter/DanielSundgren';
import Saletros from './pages/mittfaltare/Saletros';
import Gravius from './pages/exporter/Gravius';
import KristofferOlsson from './pages/exporter/KristofferOlsson';
import RobinJansson from './pages/exporter/RobinJansson';
import Lahne from './pages/exporter/Lahne';
import Carlgren from './pages/exporter/Carlgren';
import Eliasson from './pages/exporter/Eliasson';
import Antonsson from './pages/rykten/Antonsson';
import Strandberg from './pages/exporter/Strandberg';
import Vaisanen from './pages/exporter/Vaisanen';
import Kouakou from './pages/exporter/Kouakou';
import Quaison from './pages/exporter/Quaison';
import Smajic from './pages/mittfaltare/Smajic';
import ViktorFischer from './pages/mittfaltare/ViktorFischer';
import Keita from './pages/rykten/Keita';
import Harun from './pages/backar/Harun';
import Test from './pages/Test';
import Tekie from './pages/rykten/Tekie';
import PageNotFound from './pages/PageNotFound';
import Sitemap from './pages/Sitemap';
import DerbyStatistik from './pages/DerbyStatistik';
import KwakuKariKari from './pages/rykten/KwakuKariKari';
import AikHighlights from './pages/AikHighlights';
import FvsW from './pages/FvsW';
import VS from './pages/VS';
import Stamatopoulos from './pages/malvakter/Stamatopoulos';
import Aviander from './pages/mittfaltare/Aviander';
import LamineDabo from './pages/mittfaltare/LamineDabo';
import AIKSolnaFK from './pages/AIKSolnaFK';
import Utvik from './pages/rykten/BjornUtvik';
import LionelMessi from './pages/rykten/LionelMessi';
import OleSelnaes from './pages/rykten/OleSelnaes';
import IverFossum from './pages/rykten/IverFossum';
import DinoBesirovic from './pages/mittfaltare/DinoBesirovic';
import MadsThychosen from './pages/backar/MadsThychosen';
import RamiKaib from './pages/rykten/RamiKaib';
import LucasMuhl from './pages/rykten/LucasMuhl';
import MamoudouKaramoko from './pages/rykten/MamoudouKaramoko';
import AbdulFuseini from './pages/rykten/AbdulFuseini';
import IoannisPittas from './pages/rykten/IoannisPittas';
import BersantCelina from './pages/mittfaltare/BersantCelina';
import BenjaminHansen from './pages/backar/BenjaminHansen';
import JonahKusiAsare from './pages/anfallare/JonahKusiAsare';
import Diawara from './pages/malvakter/Diawara';
import Ellingsen from './pages/mittfaltare/Ellingsen';
import Coulibaly from './pages/mittfaltare/Coulibaly';
import EskilEdh from './pages/backar/EskilEdh';
import Gono from './pages/anfallare/Gono';




function App() {
  return (
    <Router>
    <div className="App">
 
    </div>
   
      <Routes>
      <Route path="/isak" element={<Isak />} />
      <Route path="/kahl" element={<Kahl />} />
      <Route path="/aaa" element={<AAA />} />
      <Route path="/strannegard" element={<Strannegard />} />
      <Route path="/abraham" element={<Abraham />} />
      <Route path="/jordanLarsson" element={<JordanLarsson />} />
      <Route path="/linner" element={<Linner />} />
      <Route path="/danielSundgren" element={<DanielSundgren />} />
      <Route path="/saletros" element={<Saletros />} />
      <Route path="/gravius" element={<Gravius />} />
      <Route path="/kristofferOlsson" element={<KristofferOlsson />} />
      <Route path="/robinJansson" element={<RobinJansson />} />
      <Route path="/lahne" element={<Lahne />} />
      <Route path="/carlgren" element={<Carlgren />} />
      <Route path="/eliasson" element={<Eliasson />} />
      <Route path="/strandberg" element={<Strandberg />} />
      <Route path="/vaisanen" element={<Vaisanen />} />
      <Route path="/kouakou" element={<Kouakou />} />
      <Route path="/quaison" element={<Quaison />} />

      <Route path="/jimmyDurmaz" element={<JimmyDurmaz />} />
      <Route path="/antonsson" element={<Antonsson />} />
      <Route path="viktorFischer" element={<ViktorFischer />} />
      <Route path="keita" element={<Keita />} />
      <Route path="tekie" element={<Tekie />} />
      <Route path="kwakuKariKari" element={<KwakuKariKari />} />
      <Route path="/bjornUtvik" element={<Utvik />} />
      <Route path="/lionelMessi" element={<LionelMessi />} />
      <Route path="/oleSelnaes" element={<OleSelnaes />} />
      <Route path="/iverFossum" element={<IverFossum />} />
      <Route path="/dinoBesirovic" element={<DinoBesirovic />} />
      <Route path="/madsThychosen" element={<MadsThychosen />} />
      <Route path="/ramiKaib" element={<RamiKaib />} />
      <Route path="/lucasMuhl" element={<LucasMuhl />} />
      <Route path="/mamoudouKaramoko" element={<MamoudouKaramoko />} />
      <Route path="/abdulFuseini" element={<AbdulFuseini />} />
      <Route path="/ioannisPittas" element={<IoannisPittas />} />
      <Route path="/bersantCelina" element={<BersantCelina />} />
      <Route path="/benjaminHansen" element={<BenjaminHansen />} />
      <Route path="/diawara" element={<Diawara />} />
      <Route path="/ellingsen" element={<Ellingsen />} />
      <Route path="/coulibaly" element={<Coulibaly />} />
      <Route path="/eskilEdh" element={<EskilEdh />} />
      <Route path="/gono" element={<Gono />} />

      <Route path="/" element={<Index />} />
      <Route path="/omOss" element={<OmOss />} />
      <Route path="/kimpioka" element={<Kimpioka />} />
      <Route path="/bahoui" element={<Bahoui />} />
      <Route path="/bilal" element={<Bilal />} />
      <Route path="/thill" element={<Thill />} />
      <Route path="/ring" element={<Ring />} />
      <Route path="/elbouzedi" element={<Elbouzedi />} />
      <Route path="/ceesay" element={<Ceesay />} />
      <Route path="/yasinAyari" element={<YasinAyari />} />
      <Route path="/tahaAyari" element={<TahaAyari />} />
      <Route path="/victorAndersson" element={<VictorAndersson />} />
      <Route path="/magashy" element={<Magashy />} />
      <Route path="/eliasDurmaz" element={<EliasDurmaz/>} />
      <Route path="/abdihakin" element={<Abdihakin/>} />
      <Route path="/fesshaie" element={<Fesshaie/>} />
      <Route path="/smajic" element={<Smajic/>} />
      <Route path="/papagiannopoulos" element={<Papagiannopoulos />} />
      <Route path="/otieno" element={<Otieno />} />
      <Route path="/milosevic" element={<Milosevic />} />
      <Route path="/axelBjornstrom" element={<AxelBjornstrom />} />
      <Route path="/forsberg" element={<Forsberg />} />
      <Route path="/shichenje" element={<Shichenje />} />
      <Route path="/bonde" element={<Bonde />} />
      <Route path="/mendes" element={<Mendes />} />
      <Route path="/modesto" element={<Modesto />} />
      <Route path="/haliti" element={<Haliti />} />
      <Route path="/faqa" element={<Faqa />} />
      <Route path="/tihi" element={<Tihi />} />
      <Route path="/harun" element={<Harun />} />
      <Route path="/nordfeldt" element={<Nordfeldt/>} />
      <Route path="/janosevic" element={<Janosevic/>} />
      <Route path="/brolin" element={<Brolin/>} />
      <Route path="/guidetti" element={<Guidetti />} />
      <Route path="/stefanelli" element={<Stefanelli />} />
      <Route path="/faraj" element={<Faraj />} />
      <Route path="/meja" element={<Meja />} />
      <Route path="/kabuye" element={<Kabuye />} />
      <Route path="/stamatopoulos" element={<Stamatopoulos />} />
      <Route path="/aviander" element={<Aviander />} />
      <Route path="/lamineDabo" element={<LamineDabo />} />
      <Route path="/jonahKusiAsare" element={<JonahKusiAsare />} />
      

      <Route path="/test" element={<Test />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/sitemap" element={<Sitemap />} />
      <Route path="/derbyStatistik" element={<DerbyStatistik />} />
      <Route path="/aikHighlights" element={<AikHighlights/>} />
      <Route path="/fvsW" element={<FvsW />} />
      <Route path="/vs" element={<VS />} />
      <Route path="/aikSolnaFk" element={<AIKSolnaFK />} />

      </Routes>
    </Router>


  );
}

export default App;
